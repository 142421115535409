import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

import ptOrange from '../images/pt_orange.gif'
import ptYellow from '../images/pt_yellow.gif'
import ptBrown from '../images/pt_brown.gif'

const CopyrightPage = () =>
  <Layout>
    <Helmet title="Hexy Music &gt; Copyright" />

    <main id="content">
      <h2>&raquo; Copyright</h2>

      <h3><img alt="" src={ ptYellow } /> Downloads</h3>
      <p>All music on this website is the copyright material of Dave Transom.
      You are however free to download, copy and listen to the music on this
      site provided it stays as it is originally intended. </p>

      <h3><img alt="" src={ ptOrange } /> Redistribution</h3>
      <p>Redistribution of any content on this site, including but not limited to
      textual data and mp3's is prohibited without our express permission. If you
      are interested in redistribution please contact me <Link to="/contact/" title="Contact Hexy Music">here</Link> for
      more information.</p>

      <h3><img alt="" src={ ptBrown } /> Design</h3>
      <p>Website design copyright October 2002 <img alt="" src={ ptYellow } /> HexyMusic <img alt="" src={ ptOrange } /> and Livedonkey. Visit <a
      href="http://livedonkey.net/" rel="external">livedonkey.net</a> for
      more information. The icon used for mp3's belongs to Nullsoft Winamp, please see <a href="http://www.winamp.com" rel="external">winamp.com</a> for more information. </p>
      <p>For any further information, please use the <Link to="/contact/" title="Contact Hexy Music">contact</Link> page.</p>
    </main>

    <aside id="ear">
      &raquo;
    </aside>
  </Layout>

export default CopyrightPage
